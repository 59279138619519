<template>
  <div>
    <heads></heads>
    <div class="main">
      <div class="main_box">
        <div class="container">
          <ul class="login_content">
            <li>
              <span class="span">账号<i></i></span>
              <van-field v-model="account" />
            </li>
            <li class="rassword">
              <span class="span">密码<i></i></span>
              <van-field v-model="password" type="password" :error-message="pass" />
            </li>
            <li class="login">
              <van-button type="primary" :loading="loading" loading-text="登录..." size="large" :disabled="zhud"
                @click="login">登录</van-button>
            </li>
            <li class="login-btn">
              <a class="i" @click="toRegister()">去注册</a>
              <p>|</p>
              <router-link to="/activation">账号激活</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="chose-register">
      <div class="chose-register-title">
        <h1>请选择您要注册的账号类型</h1>
      </div>
      <div class="chose-register-content clearfix">
        <div class="left">
          <img src="@assets/images/person.png" alt="" />
          <div class="goRegister">
            <a href="/register">去注册个人账号</a>
          </div>
        </div>
        <div class="right">
          <img src="@assets/images/persons.png" alt="" />
          <div class="goRegister">
            <a href="/unitRegister">去注册单位账号</a>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import Vue from "vue";
import $ from "jquery";
import { Field, Button, Toast, Divider } from "vant";
import dayjs from "dayjs";
import cookie from "@utils/store/cookie";
import $store from "../store";
const BACK_URL = "login_back_url";
import { login, getUserInfo } from "@api/user";

Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Divider);

import heads from "@components/noLoginHeade.vue";
import foot from "@components/foot.vue";
import "@css/ico.css";
import "@css/foot.css";

export default {
  components: {
    heads,
    foot,
  },
  data() {
    return {
      account: "",
      password: "",
      zhud: false,
      loading: false,
    };
  },
  computed: {
    pass() {
      if (this.password === "") {
        return "";
      } else if (this.password.length < 6) {
        return "密码格式错误，最少为6位";
      } else {
        return "";
      }
    },
  },
  methods: {
    toRegister() {
      this.$router.replace("/register");
    },
    login() {
      const { account, password } = this;
      if (account === "") {
        Toast("用户名不能为空");
        return;
      }
      if (password === "" || this.pass === "密码格式错误，最少为6位") {
        Toast("密码输入有误");
        return;
      }
      let that = this;
      login({ account, password })
        .then(function ({ data }) {
          that.$store.commit("LOGIN", data.token, dayjs(data.expires_time));
          if (!$store.state.app.userInfo) {
            getUserInfo().then((res) => {
              that.$store.commit("UPDATE_USERINFO", res.data.userInfo);
              const backUrl = cookie.get(BACK_URL) || "/";
              cookie.remove(BACK_URL);
              that.$router.replace({ path: backUrl });
            });
          }
        })
        .catch(function (e) {
          that.$dialog.error(e.msg);
        });
    },
    toRegister: function () {
      layui.use(["layer"], function () {
        let layer = layui.layer;
        layer.open({
          title: false,
          type: 1,
          closeBtn: 0,
          anim: 0,
          area: ["40%", "48%"],
          shadeClose: true,
          content: $(".chose-register"),
          skin: "layui-layer-lan",
          end: function () {
            $(".chose-register").hide();
          },
        });
      });
    },
  },
  destroyed: function () {
    $(".layui-layer-shade").remove();
  },
};
</script>
<style lang="less">
.main {
  width: 100%;
  min-width: 1484px;
  height: 1095px;
  background: url(../assets/images/login.jpg);
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.main_box {
  width: 1484px;
  height: 1095px;
  margin: 0 auto;
}

.container {
  width: 825px;
  height: 532px;
  padding-left: 370px;
  padding-top: 220px;
  display: flex;
}

.login_content {
  padding-top: 175px;
  padding-left: 200px;
  position: relative;
}

.login_content>li {
  width: 428px;
  height: 31px;
  display: flex;
  justify-content: space-between;
}

.login_content>li>.span {
  font-size: 18px;
  width: 50px;
  color: #574136;
  text-align: justify;
  margin-top: 15px;
}

.login_content>li>span>i {
  display: inline-block;
  width: 100%;
}

.rassword {
  padding-top: 50px;
}

.login {
  margin-top: 50px;
}

li>.van-cell {
  padding: 0;
  width: 313px;
  height: 30px;
  background: transparent;
  padding-left: 20px;
  padding-top: 10px;
}

.login_content>.rassword>.van-cell {
  padding-top: 8px;
}

.login>.van-button--primary {
  color: #fff;
  background-color: #c7b2a8;
  border: 1px solid #c7b2a8;
}

.van-divider {
  cursor: pointer;
}

.login_content>.login-btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.login_content>.login-btn>p {
  margin: 0 10px;
}

.login_content>.login-btn>a {
  font-size: 16px;
  color: #bfa9a0;
}

.login_content>.login-btn {
  width: 430px;
}

.main_box_a {
  position: absolute;
  top: 110px;
  left: 190px;
  font-size: 18px;
  width: 100px;
  color: #574136;
}

.chose-register {
  /* border: 1px solid blue; */
  display: none;
  padding: 10px 20px;
}

.chose-register .chose-register-title {
  position: relative;
  margin: 30px auto;
  font-family: HYQuanTangShiJ;
  text-align: center;
}

.chose-register .chose-register-title h1 {
  position: relative;
  padding: 120px auto;
  display: block;
  font-size: 32px;
}

.chose-register .chose-register-content {
  position: relative;
  padding: auto 50px;
}

.chose-register .chose-register-content div {
  position: relative;
}

.chose-register .chose-register-content div.left {
  text-align: center;
  float: left;
  width: 45%;
}

.chose-register .chose-register-content div img {
  width: 220px;
  height: auto;
}

.chose-register .chose-register-content div.right {
  float: right;
  width: 45%;
  text-align: center;
}

.chose-register .chose-register-content .goRegister {
  position: relative;
  margin-top: 30px;
  font-size: 22px;
  font-family: HYQuanTangShiJ;
}

.chose-register .chose-register-content .goRegister a {
  display: inline-block;
  background: #c52d3b;
  padding: 20px 25px;
  border-radius: 10px;
  color: #fff;
}
</style>
