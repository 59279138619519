<template>
  <div class="head">
    <div class="header">
      <div class="show">
        <router-link to="/" class="img"> </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      userInfo: {},
    };
  },
  mounted: function () {},
  created: function () {},
  methods: {},
};
</script>
<style scoped>
.img {
  width: 74px;
  height: 140px;
  background: url(../assets/images/shou.jpg);
  background-size: cover;
  position: absolute;
  left: 76px;
  top: 1px;
}
.header {
  width: 100%;
  height: 60px;
  background: #beb9ae;
}
.show {
  width: 1484px;
  height: 60px;
  margin: 0 auto;
  position: relative;
  display: block !important;
}
.from {
  position: relative;
  right: 0;
  font-size: 14px;
  float: right;
  height: 100%;
  cursor: pointer;
}
</style>
